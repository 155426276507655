import React from 'react';

const Link = ({ className, href, children }) => {
  const onClick = (event) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    // This stops the page from auto reload with update
    event.preventDefault();
    // This pushes new the href value withot updating anything else on page. 
    // Built in Js function 
    window.history.pushState({}, '', href);

    // This emits a Nav event to route components to re-render them
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  return (
    <a onClick={onClick} className={className} href={href}>
      {children}
    </a>
  );
};

export default Link;
